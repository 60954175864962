@import  '../../scss/media', '../../scss/variables';

.collective-sec{
    position: relative;
    
    .collect-row{
        display: flex;

        @include phone{
            flex-direction: column-reverse;
        }
       
        @include tabv-m{
            flex-wrap: wrap;
        }
    }
}

.collect-left{
    position: relative;
    flex: 1 1 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
    border-radius: 20px;
    padding: 16px;


    @include  tabv-m{
        flex: 0 0 600px;
    }

    @include  tab-m{
        flex: 0 0 738px;
    }
    
   
    @include phone-m{
        padding: 24px;
    }
}

.collect-in{
    margin-bottom: 24px;

    &.default{
        position: relative;
        padding: 16px;
        border: 1px solid rgba(255, 136, 24, 0.4);
        border-radius: 8px;
        
        .d-flex{
            justify-content: space-between;
            text-align: center;
            border: 1px solid #FF8818;
            border-radius: 8px;
            padding: 17px 24px;
            margin: 14px 0 17px;

            @include phone{
                flex-direction: column;

                >div{
                    &:not(:last-child){
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }

    .quill {
        .ql-editor{
            height: 150px;
            overflow-y: auto;
        }

        .ql-toolbar{
            border-radius: 12px 12px 0 0;
        }

        .ql-container{
            border-radius: 0 0 12px 12px;
        }
        .ql-editor ul li,.ql-editor ol li{
            color: #2B2E36 !important;
            font-size: 13px;
        }
    }
}

.web-preview{
    position: relative;
    padding: 16px;

    border: 1px solid #7625FF;
    background: #FFFFFF;
    border-radius: 12px;

    figure{
        position: relative;
        width: 100%;
        aspect-ratio: 16/5;
        margin-bottom: 8px;

        img{
            @include img($ob: cover);
            border-radius: 8px;
        }
    }

    h6{
        @include phone-m{
            font-size: 20px;
        }
    }

    p{
        color: $black;
        margin-bottom: 12px;
    }
}

.info-ul{
    list-style-type: decimal;
    padding-left: 15px;

    li{
        font-size: 14px;
        margin-bottom: 4px;
        color: $black;
    }
}

.range-div{
    position: relative;
    margin-bottom: 28px;

    p{
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        &:before{
            content: "";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 5px;
        }
    }

    input{
        height: 12px;
        background: rgba(222, 222, 222, 1);
        -webkit-appearance: none;  /* Override default CSS styles */
        appearance: none;

        &::-webkit-slider-thumb {
            -webkit-appearance: none; /* Override default look */
            appearance: none;
            width: 16px; /* Set a specific slider handle width */
            height: 20px; /* Slider handle height */
            cursor: pointer; /* Cursor on hover */
            border-radius: 2px;
            
        }
        
        &::-moz-range-thumb {
            width: 16px; /* Set a specific slider handle width */
            height: 20px; /* Slider handle height */
            cursor: pointer; /* Cursor on hover */
            border-radius: 2px;
            
        }
    }

    &.purple{
        p{
            &:before{
                background: linear-gradient(172.61deg, #7625FF 37.56%, #9924E9 94.26%);
            }
        }

        input{
            &::-webkit-slider-thumb {
                background: linear-gradient(172.61deg, #7625FF 37.56%, #9924E9 94.26%);
            }
            &::-moz-range-thumb {
                background: linear-gradient(172.61deg, #7625FF 37.56%, #9924E9 94.26%);
            }
        }
    }

    &.grey{
        p{
            &:before{
                background-color: #5C72B0;
            }
        }
        input{
            &::-webkit-slider-thumb {
                background: #5C72B0;
            }
            &::-moz-range-thumb {
                background: #5C72B0;
            }
        }
    }

}

.nft-cate{
    .checks{
        margin-bottom: 22px;
        
        label{
            font-weight: 400;
            // color: $text;
        }

        input{
            &:checked+label{
                font-weight: 600;
            }
        }

        >div{
            &:not(.checkbox){
                padding-left: 30px;
                
                .checkbox{
                    display: flex;
                    padding: 10px 0 0;

                    label{
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.collect-right{
    position: relative;
    flex: 1 1;
    margin-bottom: 24px;

    @include tabv-m{
        padding-left: 26px;
        margin-top: 0;
    }
}

.collect-card{
    background: #FFFFFF;
    border: 1px solid #FF8818;
    border-radius: 12px;
    padding: 16px;
    position: sticky;
    top: 80px;

    >figure{
        position: relative;
        width: 100%;
        aspect-ratio: 16/5;

        img{
            @include img($ob: cover);
            border-radius: 10px;
        }
    }

    .text{
        text-align: center;
        margin-top: -20px;

        figure{
            position: relative;
            width: 72px;
            aspect-ratio: 1/1;
            margin: 0 auto 20px;

            img{
                @include img($ob: cover);
                border-radius: 100%;
            }
        }

        >h6{
            margin-bottom: 12px;
        }

        .d-flex{
            justify-content: center;

            >div{
                flex: 1 1;
                padding: 0 10px;
            }
        }
    }

    &.new{

        figure{
            aspect-ratio: 16/8;
        }
        .text{
            margin: 20px 0 0;
        }

        .d-flex{
            justify-content: space-between;

            &:not(:last-child){
                margin-bottom: 12px;
            }

            >div{
                width: 50%;
                text-align: left;
                padding: 0;
                word-break: break-all;
            }
        }
    }
}

.react-datepicker-wrapper{
    width: 100%;
    position: relative;
    
}
.react-datepicker{

    .react-datepicker__triangle{
        left: -10px !important;
    }

    .react-datepicker__day--selected{
        background-color: $orange;
    }
}
