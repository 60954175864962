.d-block{
    display: block;
  }
  
  .text-center{
    text-align: center;
  }
  
  .text-left{
    text-align: left;
  }
  
  .d-flex{
    display: flex;
  }
  
  .ml-auto{
    margin-left: auto;
  }
  
  .row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  
    >*{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .form-row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  
    >*{
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  
  [class*="col-"]{
    width: 100%;
  }
  
  $sm: 576;
  $md: 768;
  $lg: 992;
  $xl: 1200;
  $col-map: (
    '': 0,
    '-sm': $sm,
    '-md': $md,
    '-lg': $lg,
    '-xl': $xl
  );
  
  @mixin rs($size, $width) {
    @if($size == 0) {
      @content;
    } @else {
      @media screen and (#{$width}-width: #{$size}px) {
        @content;
      }
    }
  }
  
  $cols:12;
  
  @mixin cols-classes($device, $cols, $size) {
    @include rs($size, 'min') {
      @for $i from 1 through $cols {
        .col#{$device}-#{$i} {
            flex: 0 0 (100 / ($cols / $i) ) * 1%;
            max-width: (100 / ($cols / $i) ) * 1%;
        }
  
        .col#{$device}-offset-#{$i} {
          margin-left: (100 / ($cols / $i) ) * 1%;
        }
      }
    }
  }
  
  
  @each $device , $size in $col-map {
    @include cols-classes($device, $cols, $size);
  }
  
  @each $device , $size in $col-map {
  
    @include rs($size, min) {
      .d#{$device}-none {
        display: none !important;
      }
  
      .d#{$device}-block {
        display: block !important;
      }
    }
  }