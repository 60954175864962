.rainbow-btn{
  background: #EEEEEE;
  box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
  border-radius: 0px 0px 10px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px ;
  font-weight: 600;
  font-size: 16px;
  border: none;

  img{
    background: rgba(248, 157, 53, 0.25);
    border-radius: 36px;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    object-fit: contain;
    padding: 5px;
  }
}
