img {
  max-width: 100%;
}

figure {
  margin: 0;
}

a[href="javascript:;"] {
  cursor: unset !important;
}

.link {
  color: $orange;
  font-weight: 600;
  background-color: transparent;
  border: none;

  &:hover {
    color: $orange;
  }

  &.disabled {
    pointer-events: none;
    color: $text;
    opacity: 0.8;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.label-status {
  color: #5c72b0;
  background-color: rgba(#5c72b0, 0.15);

  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 7px;

  &.red {
    color: #fc3838;
    background-color: rgba(#fc3838, 0.15);
  }

  &.orange {
    color: #ff8818;
    background-color: rgba(#ff8818, 0.15);
  }
}

.container {
  padding-left: 15px;
  padding-right: 15px;
  max-width: 1224px;
}

.ml-div {
  @include tabv-m {
    margin-left: auto;
  }
}

.label {
  font-weight: 400;
  font-size: 16px;
  padding: 4px 16px;
  border-radius: 50px;
  background-color: rgba(20, 167, 96, 0.1);
  color: $green;

  &.red {
    background-color: rgba($red, 0.1);
    color: $red;
  }

  &.orange {
    background-color: rgba($orange, 0.1);
    color: $orange;
  }
}

.progress {
  border-radius: 30px;
  height: 15px;

  &.md {
    height: 24px;
  }

  &.green {
    .progress-bar {
      background: $aqua;
    }
  }

  &.blue {
    .progress-bar {
      background: linear-gradient(180deg, #94abea -85.42%, #5671bc 147.92%);
    }
  }

  &.red {
    .progress-bar {
      background: linear-gradient(180deg, #ff0505 -85.42%, #a21616 147.92%);
    }
  }

  .progress-bar {
    border-radius: 20px;
    background: linear-gradient(214.02deg, #b75cff 6.04%, #671ae4 92.95%);
  }

  &.lg {
    height: 28px;
  }
}

.progress-div {
  position: relative;

  p {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    font-size: 14px;
    color: white;
    font-weight: 600;
    text-shadow: 0px 4px 6px rgba(31, 0, 82, 0.19);
    text-transform: uppercase;
  }

  &.left {
    p {
      right: auto;
      left: 10px;
    }
  }
}

%trunc {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Dropdown

.dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(31, 0, 82, 0.19);
  border-radius: 16px;
  padding: 20px 0px;
  border: none;
  min-width: 266px;

  a {
    font-weight: 700;
    font-size: 15px;
    padding: 10px 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    transition: all 200ms ease;
    color: $dark;

    .icon {
      padding-right: 6px;
    }

    &:hover {
      background-color: whitesmoke;
    }
  }
}

// Header

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1040;

  transition: all $trans 200ms;

  @include tab {
    padding-bottom: 6px;
  }

  &.scrolled {
    // background-color: rgba(0, 0, 0, 0.6);
    background-color: white;
    box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.196);
    // backdrop-filter: blur(30px);

    &.dark {
      .logo-div {
        a,
        span {
          background: url(../assets/images/logo-black.svg) no-repeat center;
          background-size: contain;

          img {
            opacity: 0;
          }
        }
      }

      .top-btn {
        background-color: rgba(0, 0, 0, 0.36);
      }

      .ham-btn {
        button {
          background-color: rgba(0, 0, 0, 0.36);
        }
      }
    }
  }

  &:not(.dark) {
    .logo-div {
      a,
      span {
        background: url(../assets/images/logo-black.svg) no-repeat center;
        background-size: contain;

        img {
          opacity: 0;
        }
      }
    }

    .ham-btn {
      button {
        background-color: rgba(235, 235, 235);

        span {
          background-color: $black;
        }
      }
    }

    &:not(.scrolled) {
      .navigation {
        nav {
          background: url(../assets/images/nav-bg2.svg) no-repeat bottom center;
          background-size: 100%;
        }
      }
    }
  }

  &.nav-active {
    .navigation {
      opacity: 1;
      visibility: visible;
      z-index: 2;
      transform: translateY(0);
    }

    .ham-btn {
      span {
        &:nth-child(1) {
          transform: rotate(-135deg);
          top: 5px;
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(135deg);
          bottom: 5px;
        }
      }
    }
  }

  .container {
    display: flex;
    // justify-content: space-between;
  }

  .header-btn {
    @include tab {
      margin-left: auto;
    }

    .top-btn {
      backdrop-filter: blur(3px);
      border-radius: 0px 0px 10px 10px;
      border: none;
      background: rgba(255, 255, 255, 0.2);
      border-top: none;
      line-height: 1.2;
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: white;

      &:after {
        display: none;
      }

      &:not(.wallet-btn) {
        padding: 11px 10px;
      }
    }
  }

  &:not(.dark) {
    .header-btn {
      .top-btn {
        &:not(.wallet-btn) {
          background: rgba(255, 255, 255, 0.2);
          color: $orange;
          border: 1px solid $orange;
          border-top: none;
        }
      }
    }
  }
}

.add-new-drop {
  .dropdown-menu {
    min-width: 182px;
    padding: 10px 0;
    left: auto !important;
    right: 10px !important;
    top: 10px !important;

    &:after {
      content: "";
      position: absolute;
      display: inline-block;
      border: 10px solid transparent;
      border-bottom-color: white;
      bottom: 100%;
      right: 14px;
    }

    a {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      padding: 12px 15px;
      margin-bottom: 0;

      &:not(:last-child) {
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 6px;
          right: 6px;
          border-bottom: 1px solid #c1c1c1;
        }
      }

      // &:not(:last-child){
      //   border-bottom: 1px solid #C1C1C1;
      // }

      &:hover {
        background-color: $orange;
        color: white;
      }
    }
  }
}

.logo-div {
  align-self: center;

  a,
  span {
    display: block;
    max-width: 150px;

    @include tab {
      padding: 4px;
      max-width: 140px;
    }

    @include phone {
      max-width: 100px;
    }
  }
}

.navigation {
  align-self: center;
  margin-left: auto;

  nav {
    align-items: center;
    background: url(../assets/images/nav-bg.svg) no-repeat top center;
    background-size: 100%;
    padding: 17px 20px;
    transition: all $trans 200ms;

    li {
      padding: 0 17px;

      .notification {
        @include tab {
          display: none;
        }
      }

      > a {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: $black;
        transition: all $trans 200ms;

        &:hover {
          color: #545fc8;
          text-decoration: underline;
        }

        &.active {
          color: $orange;
          font-weight: 700;
          cursor: unset;
          text-decoration: none;
        }
      }
    }
  }

  @include tab {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    z-index: -10;
    padding: 0;
    background: white;
    top: 50px;
    right: 20px;
    border-radius: 10px;
    overflow: hidden;
    transition: all $trans 200ms;
    transform: translateY(-10px);
    box-shadow: 1px 4px 20px -3px rgba(0, 0, 0, 0.1);

    nav {
      flex-direction: column;
      align-items: flex-start;
      background: white;

      li {
        padding: 2px 0;

        a {
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }

  @include tabv-m {
    padding-right: 30px;

    nav {
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }

  @include tab-m {
    padding-right: 32px;
  }

  .profile-btn {
    background: transparent;
    padding: 0;
    margin: 0;
    box-shadow: none;

    &:after {
      display: none;
    }

    &:hover {
      background: transparent !important;
      box-shadow: none !important;
    }

    &:active,
    &.show,
    :focus {
      background: transparent !important;
      padding: 0 !important;
      margin: 0 !important;
      box-shadow: none !important;
      outline: none;
    }

    .dot {
      position: absolute;
      width: 24px;
      height: 24px;
      background-color: #ff8818;
      border-radius: 50%;
      top: -12px;
      right: -8px;
      font-size: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.137);
      // display: none;
    }
  }
}

.header-btn {
  display: flex;
  // margin-top: -17px;

  > div {
    padding: 0 4px;

    @include phone-m {
      padding: 0 8px;
    }
  }

  .top-btn {
    @include phone {
      font-size: 10px !important;
      padding: 12px 8px 10px !important;
    }

    &.wallet-btn {
      background: $btn;
      color: white;
      box-shadow: 0px 4px 6px rgba(255, 136, 24, 0.2);

      @include phone-m {
        padding: 11px 12px;

        &:active {
          padding: 11px 12px;
          box-shadow: none;
          border-radius: 0 0 8px 8px;
          line-height: 1.2;
        }
      }
    }
  }
}

.ham-btn {
  button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    width: 36px;
    height: 32px;
    padding: 10px 8px;
    border-radius: 0 0 8px 8px;

    @include phone-m {
      width: 38px;
      height: 40px;
    }
  }

  @include tab-m {
    display: none;
  }

  span {
    position: relative;
    height: 2px;
    width: 100%;
    background-color: white;
    transition: all $trans 200ms;

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
}

.pt-top {
  padding-top: 75px;

  @include phone-m {
    padding-top: 95px;
  }
}

// Input select forms

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="file"],
input[type="date"],
input[type="time"],
textarea,
.MuiInputBase-root .MuiSelect-select,
.MuiInputBase-multiline textarea,
input:-webkit-autofill,
.form-in [class*="control"] {
  width: 100%;
  background: white;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  // border: 1px solid transparent;
  // border: 1px solid $text;
  color: $black;
  border-radius: 12px;
  padding: 13px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.15;
  min-height: auto !important;
  box-sizing: border-box;
  height: auto;

  &.Mui-disabled,
  &:disabled {
    background: rgba(241, 237, 237, 0.4) !important;
  }

  @include phone-m {
    font-size: 14px;
    padding: 14px 16px;
    line-height: 1.71;
  }

  &::placeholder {
    color: $text !important;
    font-weight: 400;
    opacity: 1 !important;
    visibility: visible !important;
    display: block;
    text-transform: capitalize;
  }

  &:focus {
    outline: none;
    border-color: $orange;
    // box-shadow: 0px 0px 0px 3px rgba($red, 0.04);
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input {
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &[type="date"] {
    position: relative;
    background: white url(../assets/images/icons/calendar-icon.svg) no-repeat;
    background-position: calc(100% - 15px) center;

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}

select {
  width: 100%;
  background: #ffffff url(../assets/images/icons/caret-down.svg) no-repeat;
  background-position: calc(100% - 20px) center;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  color: $black;
  border-radius: 12px;
  padding: 13px 40px 13px 13px;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.15;
  min-height: auto !important;
  box-sizing: border-box;
  height: auto;
  height: auto;
  appearance: none;

  option {
    font-size: 14px;
  }

  &::placeholder {
    color: $text;
  }

  &:focus {
    outline: none;
    // box-shadow: 0px 0px 0px 3px rgba($red, 0.04);
  }

  @include phone-m {
    font-size: 14px;
    padding: 14px 42px 14px 16px;
    line-height: 1.71;
  }

  &.orange {
    border-color: $orange;
    color: $orange;
    background: url(../assets/images/icons/orange-caret.svg) no-repeat;
    background-position: calc(100% - 20px) center;
    font-size: 16px;
  }
}

.otp-input {
  display: flex;
  justify-content: center;
  padding: 10px 0 4px;

  @include phone-m {
    padding: 20px 0 10px;
  }

  input {
    width: 50px !important;
    height: 50px !important;
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    padding: 2px;
    border-radius: 16px;
    border: 1px solid rgba(#888888, 0.17);

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .validation-error {
    text-align: center;
  }
}

.otp-input + .validation-error {
  text-align: center;
}

.space-below {
  .form-in {
    margin-bottom: 16px;
  }
}

.form-in {
  position: relative;
  text-align: left;

  &.f16 {
    input,
    select,
    textarea,
    option {
      @include phone-m {
        font-size: 16px;
      }
    }
  }

  [class*="control"] {
    &:hover {
      border-color: #eaeaea;
    }

    // &:active, &:focus{
    //   border-color: $orange;
    //   outline: none;
    // }

    [class*="ValueContainer"],
    > div {
      padding: 0;
      min-height: unset;
      cursor: pointer;
      background: url(../assets/images/icons/caret-down.svg) no-repeat;
      background-position: calc(100% - 5px) center;

      [class*="Input"] {
        padding: 0;
        margin: 0;
      }
    }

    [class*="menu"],
    > div {
      font-size: 14px;
    }
  }

  &.truc {
    input {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 80px;
    }
  }

  > label {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.43;
    color: $dark;
    margin-bottom: 6px;
    display: flex;
    align-items: center;

    .req {
      color: $orange;
    }
  }

  .react-tel-input {
    input {
      // background: url(../images/icons/caret-down-icon.svg) no-repeat;
      background-position: calc(100% - 10px) center;
      padding-right: 24px;
      width: 120px !important;
    }
  }

  .MuiInputBase-root {
    width: 100%;
  }

  fieldset {
    border: none;
  }

  &.phone {
    z-index: 20;

    .form-in {
      margin-bottom: 0;
    }

    .react-tel-input {
      top: 50%;
      transform: translateY(-45%);

      input {
        width: -webkit-fill-available;
      }
    }

    .f-in {
      display: flex;

      > div {
        &:nth-child(2) {
          max-width: 100%;
          padding-right: 10px;
          flex: 1 1 100%;

          input {
            padding-left: 86px;
            // border-left: none;
            // border-radius: 0px 6px 6px 0;
          }
        }
      }
    }
  }

  .react-tel-input {
    position: absolute;
    flex: 0 0;
    z-index: 2;
    width: 80px;

    .flag-dropdown {
      background-color: transparent;
      border: none;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;

      &.open {
        background-color: transparent;
      }

      .selected-flag {
        opacity: 0;
        width: 100%;
      }
    }

    .form-control,
    input {
      height: auto;
      font-size: 14px;
      padding: 14px 12px;
      line-height: 1.71;
      border: 1px solid transparent;
      border-radius: 6px 0 0 6px;
      border-right: none;
      background: url(../assets/images/icons/down-icon.svg) no-repeat;
      background-position: calc(100% - 10px) center;
      padding-right: 24px;
      max-width: 84px;

      @include phone-m {
        font-size: 14px;
      }
    }

    .country-list {
      .country-name {
        color: $black;
      }

      .country {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
  }

  &.grey {
    input,
    .MuiSelect-select,
    .MuiInputBase-multiline textarea {
      background-color: #f1eded;
      background-color: #f8f8f8;
    }
  }

  &.icon {
    .icon-left {
      position: absolute;
      top: 12px;
      left: 8px;
      z-index: 10;

      @include phonev-m {
        left: 12px;
      }

      @include phone-m {
        top: 14px;
      }
    }

    input,
    textarea {
      padding-left: 40px;

      @include phonev-m {
        padding-left: 46px;
      }
    }
  }

  &.right-icon {
    input,
    .MuiInputBase-multiline textarea,
    select,
    textarea {
      padding-right: 56px;
      overflow: hidden;
      text-overflow: ellipsis;

      @include phone-m {
        padding-right: 40px;
      }
    }
  }

  &.right-text {
    input,
    .MuiInputBase-multiline textarea,
    select,
    textarea {
      padding-right: 40px;
    }

    .icon-right {
      right: 44px;
    }
  }

  &.right-text {
    z-index: 2;

    select {
      background-color: transparent;
    }

    .icon-right {
      right: 44px;
      z-index: -1;
    }
  }

  &.left-icon {
    input,
    .MuiInputBase-multiline textarea,
    select,
    textarea {
      padding-left: 50px;
    }
  }

  %icon {
    position: absolute;
    top: 50%;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all $trans 180ms;
    transform: translateY(-50%);

    &:hover {
      opacity: 0.9;
    }
  }

  .icon-right {
    @extend %icon;
    right: 18px;
  }

  .icon-left {
    @extend %icon;
    left: 16px;
  }

  .btn-right {
    position: absolute;
    right: 10px;
    top: 9px;
    z-index: 2;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all $trans 180ms;

    @include phone-m {
      top: 11px;
    }

    button {
      box-shadow: none;
    }

    .copied {
      position: absolute;
      left: 0;
      right: 0;
      top: 40px;
      background-color: #13af28;
      color: white;
      padding: 4px 10px;
      font-size: 12px;
      border-radius: 4px;
      opacity: 0;
      visibility: hidden;
      font-weight: 500;

      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .f-in {
    position: relative;

    .MuiFormControl-root {
      width: 100%;
    }

    label {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.43;
      color: $text;
      transition: all cubic-bezier(0.15, 0.66, 0.71, 0.93) 200ms;

      @include phone-m {
        font-size: 16px;
      }
    }
  }

  .error-msg {
    // display: none;
    color: $red;
    font-size: 14px;
    margin-top: 4px;
  }

  &.error {
    input,
    select,
    textarea {
      border-color: $red;
    }

    .error-msg {
      display: block;
    }
  }

  &.icon .custom-label:not(.Mui-focused) {
    position: absolute;
    position: absolute;
    background-color: white;
    padding: 0px 4px;
    // font-size: 16px;
    top: -8px;
    z-index: 2;
    left: -4px;
    transform: scale(0.7) !important;
    color: $text;
  }
}

.password-info {
  position: absolute;
  background: white;
  z-index: 10;
  left: auto;
  top: calc(100% + 10px);
  right: 0;
  min-width: 227px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 16px;
  z-index: 100;

  @include phone-m {
    left: calc(100% + 10px);
    top: 0;
    right: a;
  }

  p {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  ul {
    margin-bottom: 0;
    padding-left: 12px;
    list-style-type: disc;

    li {
      margin-bottom: 2px;
      font-size: 11px;
      color: $black;
    }
  }
}

.checkbox {
  position: relative;
  display: inline-block;

  .link {
    position: relative;
    z-index: 10;
  }

  label {
    position: relative;
    margin-bottom: 0;
    line-height: 1;
    display: flex;
    // align-items: center;
    font-weight: 500;
    font-size: 14px;
    color: $black;
    line-height: 1.5;

    &:before {
      content: "";
      width: 18px;
      height: 18px;
      position: relative;
      display: inline-block;
      background: transparent url(../assets/images/icons/check-icon.svg)
        no-repeat;
      background-position: 15px 0px;
      // background-color: #FFFFFF;
      border: 1px solid #676767;
      border-radius: 2px;
      margin-right: 10px;
      margin-top: 1px;
      flex-shrink: 0;
    }
  }

  &.short{
    input{
      max-width: 60px;
    }
  }

  input {
    @include str-i;

    &:checked + label {
      color: $black;
      font-weight: 700;
      // border-color: $blue;

      &:before {
        background-color: $orange;
        border-color: $orange;
        // box-shadow: 0px 0px 0px 1px rgba($blue, 0.32);
        background-position: center;
      }
    }

    &:focus + label {
      &:before {
        border-color: $orange;
      }
    }
  }

  &.f16 {
    label {
      font-size: 16px;

      &:before {
        margin-top: 4px;
      }
    }
  }
}

.radio {
  position: relative;
  display: inline-block;
  padding: 15px 0 16px;

  label {
    position: relative;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5;
    display: flex;
    align-items: flex-start;
    color: $dark;

    &:before {
      content: "";
      width: 20px;
      height: 20px;
      flex-shrink: 0;
      position: relative;
      display: inline-block;
      // background: url(../images/icons/check-white.svg) no-repeat;
      background-position: 20px;
      background-size: 12px;
      background: #ffffff url(../assets/images/icons/radio.svg) no-repeat;
      background-position: 20px;
      border: 1px solid #c8ccd7;
      border-radius: 50%;
      margin-right: 10px;
      top: 0px;
    }
  }

  input {
    @include str-i;

    &:checked + label {
      color: $dark;

      &:before {
        background-position: center;
        border: 1px solid $orange;
        box-shadow: 0px 0px 0px 1px rgba($blue, 0.12);
      }
    }
  }

  &.sm {
    label {
      font-size: 14px;
    }
  }
}

// Button

@mixin btn(
  $fs: 16px,
  $pd: 11px 40px,
  $pdl: 14px 24px,
  $fw: 600,
  $bg: linear-gradient(115.31deg, #ff8818 -28.13%, #ffcf18 171.11%),
  $color: white,
  $border: none,
  $hc: white,
  $box: 0px 4px 6px rgba(255, 136, 24, 0.2),
  $br: 9px,
  $hbg: linear-gradient(115.31deg, #ff8818 -28.13%, #ffcf18 171.11%),
  $disabled: false
) {
  font-family: $font;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: $fw;
  line-height: 1.38;
  padding: 10px 18px;
  border-radius: $br;
  background: $bg;
  color: $color;
  border: $border;
  cursor: pointer;
  box-shadow: $box;
  transition: all $trans 240ms;
  // text-transform: uppercase;

  @include phone-m {
    font-size: $fs;
    padding: $pd;
  }

  [class*="icon"] {
    margin-right: 6px;
  }

  &.sm {
    padding: 6px 12px;
    min-width: auto;
    font-size: 12px;
  }

  &.lg {
    padding: $pdl;
  }

  // &:focus {
  //   animation: click 290ms ease forwards;
  // }

  &:hover {
    color: $hc;
    border-color: $orange;
    box-shadow: 4px 4px 6.46px rgba(6, 6, 6, 0.07);
    filter: contrast(1.1) brightness(1.05);
    background: $hbg;
  }

  &:focus,
  &:active {
    color: $hc;
    outline: none;
    border-color: $orange;
    box-shadow: 0px 0px 0px 2px rgb(188, 216, 255);
  }

  @if $disabled {
    pointer-events: none;
    cursor: unset;
    box-shadow: none;
    opacity: 0.6;

    &:hover {
      color: #929191;
      box-shadow: none;
    }
  }

  &.spinner {
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      border: 3px solid white;
      border-radius: 50%;
      border-left-color: transparent !important;
      margin-left: 6px;
      animation: spin 500ms linear infinite forwards;
      flex-shrink: 0;
    }
  }

  &.w-200 {
    min-width: 200px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes click {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.95);
  }
}

.btn,
.btn-primary,
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  @include btn;
}

.btn.disabled,
.btn:disabled {
  @include btn($disabled: true);
}

.btn-blank {
  @include btn(
    $bg: transparent,
    $color: $text,
    $border: transparent,
    $hbg: $white,
    $hc: $orange
  );
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.btn-white {
  @include btn(
    $pd: 10px 24px,
    $pdl: 13px 24px,
    $bg: white,
    $color: $orange,
    $hbg: white,
    $hc: $orange,
    $border: 1px solid $orange
  );
  box-shadow: none;

  &.spinner {
    &:after {
      border-color: $orange;
    }
  }
}

.btn-line {
  @include btn(
    $pd: 10px 24px,
    $pdl: 13px 24px,
    $bg: transparent,
    $color: white,
    $hbg: white,
    $hc: $orange,
    $border: 1px solid white
  );
  box-shadow: none;

  &:focus{
    border-color: $white;
    background-color: $white;
    box-shadow: 0px 0px 0px 2px rgb(255, 213, 188);
  }
}

.btn-grey {
  @include btn(
    $fs: 18px,
    $pd: 10px 16px,
    $pdl: 10px 16px,
    $bg: white,
    $color: $purple,
    $hbg: white,
    $hc: $black,
    $border: 1px solid $gray
  );
  box-shadow: none;
}

.btn-fade {
  @include btn(
    $bg: rgba(255, 255, 255, 0.5),
    $color: white,
    $hbg: white,
    $hc: $purple,
    $border: transparent
  );
  box-shadow: none;

  &:hover {
    border-color: transparent;
  }
}

.btn-purple {
  @include btn(
    $bg: white,
    $border: 1px solid $purple,
    $color: $purple,
    $box: none,
    $br: 8px,
    $pd: 12px 24px,
    $hc: white,
    $hbg: $purple
  );

  span {
    background: linear-gradient(172.61deg, #7625ff 37.56%, #9924e9 94.26%);
    transition: all $trans 240ms;
  }

  &:not(:hover) {
    span {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  i {
    transition: all $trans 100ms;
  }

  &:hover {
    border-color: $purple;

    span {
      background: transparent;
      color: white;
    }

    i {
      filter: invert(1) brightness(10);
      transition: all $trans 300ms;
    }
  }
}

.MuiSwitch-root.switch-custom {
  padding: 0;
  width: 46px;
  height: auto;

  .Mui-checked + .MuiSwitch-track {
    background: #fff0e3;
    border: 0.5px solid #4d61aa;
  }

  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: $blue;
    }
  }

  .MuiSwitch-track {
    background: #bcbcbc;
    border: 0.5px solid #6f6f6f;
    border-radius: 100px;
    height: 24px;
    flex: 1 1;
  }

  .MuiSwitch-switchBase {
    padding: 0px;
    top: 3px;
    left: 3px;
  }

  .MuiSwitch-thumb {
    width: 18px;
    height: 18px;
  }
}

// Modal

.modal {
  &.show {
    // display: flex !important;
    align-items: center;
    justify-content: center;
  }

  @include phone {
    padding: 20px 20px !important;
  }
}

.modal-dialog {
  flex: 1 1 100%;
  max-width: 320px;

  @include phone {
    margin: 32px auto 0;
  }

  @include phone-m {
    max-width: 430px;
  }

  .modal-content {
    box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
    border-radius: 20px;
  }

  .modal-header {
    border-bottom: none;
    padding: 36px 24px 26px;

    @include phone-m {
      padding: 36px 40px 26px;
    }
  }

  .modal-body {
    padding: 0px 24px 34px;

    &:first-child {
      padding-top: 34px;
    }

    @include phone-m {
      padding: 0px 40px 34px;
    }
  }

  .modal-footer {
    flex-direction: column;
    border-top: none;
    padding: 0 24px 36px;

    @include phone-m {
      padding: 0 40px 36px;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      width: 100%;
      margin: 0;

      button,
      a,
      div {
        flex: 1 1;

        &:not(:last-child) {
          margin-right: 10px;
        }

        &.back-modal {
          flex: 0 1 auto;
        }

        &.skip {
          flex: 0 1 100px;
        }
      }
    }
  }

  .btn-close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -5px;
    right: -5px;
    background: white url(../assets/images/icons/close-icon.svg) no-repeat
      center;
    background-size: contain;
    border-radius: 100px;
    opacity: 1;
    border: 2px solid white;
    padding: 0;

    @include phone-m {
      width: 40px;
      height: 40px;
      top: -10px;
      right: -10px;
    }
  }

  .added-links {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;

    a {
      padding: 10px 10px 0;
    }
  }
}

.modal-float-btn {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 10px 20px 20px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.contribute-another {
  .modal-dialog {
    max-width: 805px;
  }

  .modal-header {
    .bg-grey {
      background: rgba(92, 114, 176, 0.051);
      border-radius: 10px;
      padding: 6px 16px;
      text-align: center;
    }
  }

  .modal-body {
    padding-top: 24px;
    padding-bottom: 10px;

    max-height: 600px;
    overflow: auto;

    @include no-scrollbar;
  }

  .nft-another {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    overflow-y: scroll;

    > div {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 10px;
    }
  }
}

.claim-modal {
  text-align: center;
  .modal-header {
    flex-direction: column;

    div {
      display: inline-block;
      border-radius: 50%;
      border: 3px solid white;
      margin-bottom: 24px;
      box-shadow: 0px 2px 10px 0px rgba(255, 136, 24, 0.129);
    }
  }
}

.comments-voters {
  .modal-header {
    padding-top: 20px;
  }

  .modal-body,
  .modal-header,
  .modal-footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-dialog {
    max-width: 573px;

    @include phone-m {
      max-width: 713px;
    }
  }

  .tabs {
    .nav-tabs {
      justify-content: flex-start;
      padding-top: 10px;

      li {
        flex: 0 0;
        padding: 0;
        margin-right: 10px;

        button {
          font-size: 14px;
          min-width: 100px;
          white-space: nowrap;
          font-weight: 600;
          padding: 12px 0;
        }
      }
    }
  }
}

.voter-info-modal {
  .modal-dialog {
    max-width: 520px;
  }

  .voter-info {
    @include card($w: 38px, $h: 38px, $pad: 0 0 16px);

    &:not(:last-child) {
      border-bottom: 1px solid #c1c1c1;
      margin-bottom: 16px;
    }

    img {
      border-radius: 50%;
    }

    .text {
      &:not(:first-child) {
        padding-left: 10px;
      }

      p {
        &.lg {
          @include trunc(20ch);
        }
      }
    }
  }
}

.add-comment {
  .modal-body,
  .modal-header,
  .modal-footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-dialog {
    max-width: 394px;
  }
}

.voter-info {
  .modal-dialog {
    max-width: 520px;
  }

  &.collection-info {
    .carousel-indicators {
      display: none;
    }
  }
}

.voter-card {
  @include card($w: 38px, $h: 38px, $pad: 16px 0, $bg: transparent);

  img {
    border-radius: 50%;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
  }

  .text {
    &:not(:first-child) {
      padding-left: 12px;
    }
  }
}

.creative-launch {
  // &.show{
  //   figure{
  //     img{
  //       opacity: 1;
  //       transform: scale(1);
  //       transition-delay: 2000ms;
  //     }
  //   }
  // }

  &.show {
    figure {
      img {
        opacity: 1;
        transform: scale(1);
        top: 0;
        // transition-delay: 1500ms;
        transition-property: opacity, transform, top;
        transition-delay: 1500ms, 1500ms, 2100ms;
      }
    }

    .modal-body,
    .modal-footer {
      opacity: 1;
      transition-delay: 2300ms;
    }

    .confetti {
      opacity: 0;
      visibility: hidden;
      transition: all 200ms ease 2000ms;
    }
  }

  .modal-content {
    position: relative;
  }

  // .modal-header {
  //   position: relative;
  //   z-index: 4;
  // }

  .modal-dialog {
    max-width: 554px;
  }

  .modal-body {
    text-align: center;
    position: relative;
  }

  figure {
    position: relative;
    align-self: center;
    margin: 0 auto;
    width: 100%;
    text-align: center;

    img {
      // opacity: 0;
      // transform: scale(0.4);
      // transition: all ease 300ms;
    }
  }

  input {
    padding-right: 50% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.proposal-modal {
  .modal-dialog {
    max-width: 556px;
  }

  .modal-body {
    padding: 0 !important;
  }

  figure {
    position: relative;
    background-color: #f5f5f5;
    min-height: 394px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border-radius: 20px 20px 40px 40px;

    img {
      height: 100%;
      width: 412px;
      display: block;
      margin: 0 auto;
      object-fit: contain;
    }

    &.long {
      img {
        max-width: 266px;
      }
    }
  }

  .text {
    padding: 0 40px;

    @include phone-m {
      min-height: 160px;
    }
  }

  .carousel {
    > a {
      display: none;
    }
  }

  .carousel-indicators {
    position: absolute;
    top: 360px;
    margin: 0;

    button {
      border-radius: 20px;
      background-color: #d9d9d9;
      margin: 0 4px;
      padding: 0;
      box-sizing: border-box;
      text-indent: 0;
      background-clip: unset;
      border: none;

      &:not(.active) {
        width: 8px;
        height: 8px;
      }

      &.active {
        width: 36px;
        height: 8px;
        background: $btn;
      }
    }
  }
}

.onboarding-modal {
  .modal-dialog {
    max-width: 556px;
  }

  .modal-body {
    padding: 0 !important;
  }

  figure {
    position: relative;
    background-color: #f5f5f5;
    min-height: 320px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 32px;
    border-radius: 20px 20px 40px 40px;

    @include phone-m {
      min-height: 394px;
    }

    img {
      height: 100%;
      width: 100%;
      display: block;
      margin: 0 auto;
      object-fit: contain;
    }

    &.long {
      img {
        max-width: 266px;
      }
    }
  }

  .text {
    padding: 0 24px 24px;

    @include phone-m {
      min-height: 120px;
      padding: 0 40px;
    }
  }

  .carousel {
    > a {
      display: none;
    }
  }

  .carousel-indicators {
    position: absolute;
    top: 280px;
    margin: 0;

    @include phone-m {
      top: 360px;
    }

    button {
      border-radius: 20px;
      background-color: #d9d9d9;
      margin: 0 4px;
      padding: 0;
      box-sizing: border-box;
      text-indent: 0;
      background-clip: unset;
      border: none;

      &:not(.active) {
        width: 8px;
        height: 8px;
      }

      &.active {
        width: 36px;
        height: 8px;
        background: $btn;
      }
    }
  }
}

.success-modal {
  .modal-header {
    justify-content: center;
  }

  .modal-body {
    text-align: center;

    p {
      max-width: 303px;
      margin: 0 auto;
    }
  }
}

.confetti {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.success-contri {
  &.show {
    figure {
      img {
        opacity: 1;
        transform: scale(1);
        top: 0;
        // transition-delay: 1500ms;
        transition-property: opacity, transform, top;
        transition-delay: 1500ms, 1500ms, 2100ms;
      }
    }

    .modal-body,
    .modal-footer {
      opacity: 1;
      transition-delay: 2300ms;
    }

    .confetti {
      opacity: 0;
      visibility: hidden;
      transition: all 200ms ease 2000ms;
    }
  }

  .modal-dialog {
    max-width: 500px;
    text-align: center;
  }

  .modal-header {
    justify-content: center;
    position: relative;
    z-index: 4;
  }

  .modal-body,
  .modal-footer {
    opacity: 0;
    transition: all ease 200ms;
  }

  figure {
    img {
      position: relative;
      opacity: 0;
      transform: scale(0.4);
      top: 70px;
      transition: all ease 400ms;
    }
  }
}

.onboarding {
  .modal-header {
    justify-content: center;
  }
}

.wallet-modal {
  .connect-card {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.email-verify {
  display: flex;
  background: rgba(234, 234, 234, 0.3);
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;

  i {
    margin-right: 32px;
    flex-shrink: 0;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: $text;
    word-break: break-word;
  }
}

.collective-preview {
  .modal-dialog {
    max-width: 554px;
  }

  .modal-body {
    padding-bottom: 0;
  }

  .collect-right {
    padding-left: 0;
  }

  .modal-header {
    .collect-card {
      flex: 1 1 100%;
    }
  }

  .row {
    > div {
      margin-bottom: 18px;

      p {
        word-wrap: break-word;
      }
    }
  }

  .added-img {
    img {
      aspect-ratio: 16/8;
      border-radius: 6px;
    }
  }
}

.eth-input {
  display: flex;
  margin-bottom: 14px;

  h5 {
    padding: 12px;
    min-width: 70px;
    background-color: $orange;
    color: white;
    margin-bottom: 0;
    border-radius: 10px 0 0 10px;
    text-align: center;

    @include phone-m {
      min-width: 140px;
    }
  }

  input {
    max-width: 250px;
    border: 2px solid $orange;
    border-radius: 0 10px 10px 0;
    padding: 10px;

    @include phone {
      font-size: 14px;
    }
  }

  &.full {
    input {
      max-width: calc(100% - 60px);
      flex: 1 1;

      @include phone-m {
        max-width: calc(100% - 140px);
      }
    }
  }
}

.add-contribute {
  .modal-header {
    justify-content: flex-start;
    padding-bottom: 12px;

    h5 {
      margin-bottom: 0;
    }
  }

  .modal-dialog {
    max-width: 512px;
  }
}

// Discover banner

.banner-section {
  position: relative;
  // min-height: 768px;
  padding: 110px 0 66px;
  overflow: hidden;

  &:not(.event-banner) {
    background: url(../assets/images/banner-bg.png) bottom center;
    background-size: cover;
    color: white;
    .text {
      p {
        color: white;
      }
    }
  }

  &.event-banner {
    .text {
      .h3 {
        margin-bottom: 24px;
      }
      p {
        color: white;
        font-weight: 400;
        color: $text;
      }
    }
  }

  .text {
    position: relative;
  }

  .row {
    align-items: center;
  }

  .text {
    @include tabv {
      margin-bottom: 32px;
    }

    .h3 {
      @include tabv-m {
        line-height: 1.93;
        font-size: 30px;
      }
    }

    p {
      margin-bottom: 26px;
      max-width: 555px;

      @include tabv-m {
        margin-bottom: 26px;
      }
    }
  }
}

.validation-error {
  width: 100%;
  margin-top: 7px;
  font-size: 80%;
  color: $red;
  display: block;
}

.featured-games {
  @include sec($pt: 0px);

  @include phone {
    padding-top: 0px;
  }
}

.games-cards {
  position: relative;
  display: flex;
  margin: 0 -12px;
  flex-wrap: wrap;

  > div {
    position: relative;
    flex: 0 0 100%;
    padding: 0 12px 24px;

    @include tabv-m {
      flex: 0 0 33.333%;
    }
  }
}

.games-card {
  position: relative;

  figure {
    padding-bottom: 63%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .game-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 6px 16px;
    color: white;

    p {
      color: white;
      font-weight: 600;
      font-size: 14px;

      @include phone-m {
        font-size: 17px;
      }
    }

    .label {
      font-weight: 600;
      font-size: 13px;
      line-height: 1.77;
      padding: 6px 10px;
      background: rgba(255, 255, 255, 0.14);
      border-radius: 3px;
    }
  }
}

// NFT cards

.featured-collectives {
  @include sec;
}

.heading {
  display: flex;
  margin-bottom: 24px;

  &:not(.profile-heading) {
    flex-direction: column;

    @include tabv-m {
      flex-direction: row;
    }
  }

  @include tabv-m {
    flex-direction: row;
    align-items: center;
  }

  @include tabv {
    margin-bottom: 32px;
  }

  h2,
  h5 {
    margin-bottom: 0;
  }

  &.profile-heading {
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    align-items: center;

    h5 {
      @include phone-m {
        font-size: 24px;
      }
    }

    .close-icon {
      width: 36px;
      height: 36px;
      background-size: contain;
    }
  }
}

.web-btn {
  @include tabv {
    display: none;
  }
}

.phn-btn {
  padding-top: 12px;

  @include tabv-m {
    display: none;
  }

  a,
  button {
    width: 100%;
  }
}

.mob-btn {
  padding-top: 12px;

  @include tabv {
    a,
    button {
      width: 100%;
    }
  }
}

.nft-slider {
  .react-multi-carousel-list {
    // overflow: visible;
    @include phone {
      padding-left: 12px;
    }

    @include tabv-m {
      padding: 40px;
      margin: -40px;
    }
  }

  .react-multi-carousel-track {
    margin: 0 -12px;
    li {
      padding: 0 12px;

      @include phone-m {
        max-width: 406px;
      }
    }
  }

  .nft-card {
    box-shadow: 0px 0px 18px -5px rgba(0, 0, 0, 0.1);
  }
}

.nft-cards {
  display: flex;
  margin: 0 -12px;

  @include tab-m {
    flex-wrap: wrap;
  }

  @include tab {
    overflow-x: auto;
  }

  > div {
    flex: 0 0 100%;
    max-width: 320px;
    padding: 0 12px;

    @include phone-m {
      max-width: 370px;
    }

    @include tab-m {
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
}

@keyframes skeleton {
  0% {
    background-position: 110%;
  }

  100% {
    background-position: -30%;
  }
}

@mixin skel {
  background: linear-gradient(90deg, #e1e1e1 21%, #efefef 50%, #e1e1e1 85%);
  background-size: 300%;
  animation: skeleton 1000ms ease infinite forwards;
}

.nft-card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 24px;
  transition: all $trans 200ms;

  &.loader {
    figure {
      background-color: whitesmoke;
      border-radius: 6px;
      @include skel;

      img {
        display: none;
      }
    }

    .text {
      min-height: 42px;
      background-color: whitesmoke;
      padding: 0;
      margin: 12px 0 20px;
      border-radius: 6px;
      @include skel;

      > * {
        display: none;
      }
    }

    .progress-div {
      p {
        display: none;
      }
    }

    .progress {
      background-color: whitesmoke;
      @include skel;
      div,
      p {
        display: none;
      }
    }

    .lower {
      p {
        color: transparent;
        width: 33.333%;

        span {
          color: transparent !important;
          background-color: whitesmoke;
          min-width: 100%;
          height: 20px;
          border-radius: 6px;
          @include skel;
        }

        &:after {
          display: none;
        }
      }
    }
  }

  &.active {
    box-shadow: 0px 4px 6px 0px #ff881833;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid $orange;
      border-radius: 6px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.35);
    transform: translateY(-5px);
  }

  @include phone-m {
    margin-bottom: 32px;
  }

  .img {
    position: relative;
    padding-bottom: 89%;
    position: relative;

    img {
      @include img($ob: cover);
      border-radius: 10px;
    }
  }

  .bookmark-btn {
    position: absolute;
    top: 12px;
    right: 12px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(2px);
    border-radius: 8px;
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    transition: all $trans 200ms;

    &:hover {
      background: rgba(255, 255, 255, 0.4);
    }
  }

  .text {
    position: relative;
    display: flex;
    padding: 12px 0 20px;

    figure {
      flex-shrink: 0;
      width: 45px;
      aspect-ratio: 1/1;

      img {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-size: 10px;
      }
    }

    .reward-icons {
      display: flex;
      margin-left: auto;
      flex-shrink: 0;
      padding-top: 2px;

      i {
        margin-left: 1px;
      }
    }

    .title {
      padding-left: 12px;
      flex: 1 1 100%;
      max-width: calc(100% - 45px);

      display: flex;

      .title-name {
        flex: 1 1;
        > p {
          font-size: 14px;
          // @include trunc(16ch);

          @include phone-m {
            font-size: 15px;
            // @include trunc(21ch);
          }

          > span {
            @include trunc(16ch);
            display: inline-block;
            @include phone-m {
              @include trunc(21ch);
            }
          }
        }
      }

      .tooltip-div {
        .text {
          white-space: nowrap;
        }
      }

      .by-name {
        display: block;
        font-weight: 400;
        color: $text;
        font-size: 12px;

        @include phone-m {
          font-size: 14px;
        }

        b {
          color: $black;
        }
      }
    }
  }

  .percent {
    margin-left: auto;
    color: $orange;
    font-weight: 700;
    font-size: 12px;
    padding-left: 12px;

    @include phone-m {
      font-size: 14px;
      white-space: nowrap;
    }
  }

  .card-details {
    .lower {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 14px;
      margin: 0 -2px;

      > p {
        position: relative;
        padding: 0 2px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        // flex: 0 0 33.33%;
        font-size: 10px;

        .price {
          display: flex;
          align-items: center;
        }

        .text {
          white-space: nowrap;
        }

        @include phone-m {
          font-size: 13px;
        }

        @include tab-m {
          font-size: 13px;
        }

        &:not(:last-child) {
          &:after {
            content: "";
            display: inline-block;
            position: relative;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            height: 16px;
            background-color: #d9d9d9;
            margin-left: 6px;

            @include phone-m {
              margin-left: 4px;
            }

            @include tab-m {
              margin-left: 14px;
            }
          }
        }

        &:nth-child(2) {
          text-align: center;
        }

        i {
          margin-top: -1px;

          &:first-child {
            margin-right: 3px;
          }
        }

        span {
          display: inline-block;
          color: $purple;
          margin-right: 3px;

          &.trunc {
            @include trunc(6ch);
          }
        }
      }
    }
  }

  .progress {
    height: 24px;
    border-radius: 40px;
  }

  .progress-bar {
    font-weight: 700;
    font-size: 12px;
    background: linear-gradient(97.23deg, #7625ff 41%, #9924e9 61.05%);
    border-radius: 40px;
  }
}

.bookmark-cards {
  .nft-card {
    .text {
      padding-bottom: 10px;
    }

    .title {
      padding-left: 0;
    }

    .card-details {
      .lower {
        p {
          font-size: 15px;
          font-weight: 600;

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

// Collectives

.collectives {
  @include sec($pt: 90px);

  @include phone {
    padding-top: 40px;
  }
}

.btns-grp {
  display: flex;
  background: rgba(#c5c5c5, 0.25);
  // border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
  border-radius: 10px;
  padding: 4px;
  margin: 0 -6px 0 0;
  justify-content: space-between;
  margin-top: 12px;

  @include tabv-m {
    padding: 7px;
    justify-content: unset;
    margin-top: 0;
  }

  > div {
    &:not(:last-child) {
      padding-right: 6px;
    }
  }

  button,
  a {
    font-size: 12px;
    padding: 8px 10px;
    font-weight: 500;
    color: $text;

    @include phone-m {
      font-size: 16px;
      padding: 8px 16px;
    }

    @include tab-m {
      font-size: 20px;
      padding: 8px 30px;
    }

    i {
      margin-left: 10px;
    }

    &:focus {
      box-shadow: none !important;
    }

    &.active {
      background: white;
      color: $orange;
    }
  }
}

// Collector rankings

.collector-ranks {
  @include sec;
}

.rank-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;

  > div {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 14px 28px;

    @include tabv-m {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.rank-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 12px;
  border-radius: 10px;

  background: linear-gradient(180deg, #7725ff 0%, #e72777 100%);
  box-shadow: 0px 4px 18px rgba(50, 48, 48, 0.15);
  transition: all $trans 200ms;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    left: 1px;
    right: 1px;
    bottom: 1px;
    top: 1px;
    background: #ffffff;
    border-radius: 8px;
    transition: all $trans 200ms;
    z-index: -1;
    outline: 1px solid white;
  }

  &:hover {
    box-shadow: 0px 4px 18px rgba(50, 48, 48, 0.15);
    transform: translateY(-2px);

    &:after {
      background-color: #f4fffd;
      outline: 1px solid transparent;
      border-radius: 10px;
    }
  }

  @include phone-m {
    padding: 20px;
  }

  figure {
    width: 42px;
    height: 42px;
    flex-shrink: 0;

    @include phone-m {
      width: 56px;
      height: 56px;
    }

    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > .p {
    padding-right: 12px;
    font-weight: 700;
    font-size: 15px;
  }

  .text {
    padding: 0 12px 0;
    flex: 1 1;

    @include phone-m {
      padding: 0 12px 0 20px;
    }

    h6 {
      font-weight: 500;
      margin-bottom: 4px;
      font-size: 14px;
      @extend %trunc;

      @include tabv-m {
        font-size: 16px;
      }

      @include tab-m {
        font-size: 18px;
      }
    }

    .h6 {
      color: $purple;
      margin-bottom: 0;

      i {
        vertical-align: 0px;
      }
    }
  }

  .btn-white {
    @include phone {
      padding: 12px 10px;
    }
  }
}

// Collective rankings

.collective-ranks {
  @include sec;
}

.collective-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;

  > div {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 14px 28px;
    display: flex;

    @include tabv-m {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 14px 28px;
    }
  }
}

.collective-card {
  flex: 1 1 100%;
  position: relative;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(50, 48, 48, 0.15);
  border-radius: 10px;
  transition: all $trans 200ms;

  .label-in-card {
    padding-top: 12px;
    @include tabv {
      width: 100%;

      .label {
        display: inline-block;
      }
    }

    @include phone-m {
      margin-left: auto;
      padding-top: 0;
    }
  }

  .rank-no {
    font-weight: 700;
    padding-right: 12px;
    font-size: 14px;

    @include phone-m {
      padding-left: 4px;
      font-size: 16px;
    }
  }

  .top {
    display: flex;
    align-items: center;

    @include phone {
      flex-wrap: wrap;
    }
  }

  .lower {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    text-align: center;

    @include phone-m {
      padding-top: 24px;
    }

    @include phonev {
      flex-wrap: wrap;
    }

    > div {
      padding: 0 5px;

      @include phonev {
        width: 50%;
        margin-bottom: 12px;
        text-align: left;
      }
    }

    p {
      &:not(.lg) {
        margin-bottom: 4px;
        color: $text;
      }

      &.lg {
        font-weight: 600;
      }
    }
  }

  &:hover {
    box-shadow: 0px 4px 18px rgba(50, 48, 48, 0.2);
    transform: translateY(-2px);
  }

  figure {
    position: relative;
    height: 50px;
    width: 50px;
    flex-shrink: 0;

    @include phone-m {
      height: 70px;
      width: 70px;
    }

    img {
      @include img($ob: cover);
      border-radius: 10px;
    }
  }

  .text {
    flex: 1 1;
    max-width: 354px;
    padding: 0 16px;

    .progress-div {
      p {
        font-size: 12px;
        font-weight: 700;
      }
    }

    > p {
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 4px;
      @extend %trunc;
    }

    span {
      font-weight: 400;
      font-size: 9.95556px;
      display: block;
      margin-bottom: 8px;
    }
  }

  .price {
    @include phone {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 12px;
    }

    @include phone-m {
      margin-left: auto;
      width: 100px;
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0px;

      @include phone-m {
        margin-bottom: 8px;
      }

      i {
        vertical-align: 0px;
      }
    }

    p {
      font-weight: 400;
      font-size: 10px;
      margin-bottom: 0;
      padding-left: 12px;

      @include phone-m {
        padding-left: 0px;
        margin-bottom: 2px;
      }
    }

    span {
      display: block;
      color: #5f5f5f;
      font-size: 10px;

      @include phone {
        margin-left: auto;
      }
    }
  }
}

.cta-banner {
  position: relative;
  text-align: center;
  background: linear-gradient(214.02deg, #b75cff 6.04%, #671ae4 92.95%);
  border-radius: 10px;
  padding: 50px 0;
  overflow: hidden;

  .h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 1.5;
    color: white;

    @include phone-m {
      margin-bottom: 20px;
      font-size: 50px;
    }
  }

  .btn {
    @include phone-m {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}

// Footer

.main-footer {
  position: relative;
  padding: 60px 0 10px;
  overflow: hidden;

  .rights {
    text-align: center;
    color: $text;
    font-weight: 500;
    font-size: 12px;
  }
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  flex-direction: row;

  @include phone-m {
    flex-direction: row;
  }
}

.footer-logo {
  @include phone {
    width: 100%;
    margin-bottom: 32px;

    p {
      max-width: 250px;
    }
  }

  figure {
    margin-bottom: 12px;

    @include phone {
      max-width: 160px;
    }
  }

  p {
    color: $text;
    max-width: 340px;
  }
}

.quick-links {
  padding-right: 50px;

  @include phone-m {
    margin-left: auto;
    padding: 0 50px;
  }

  h6 {
    margin-bottom: 20px;
  }

  .links {
    a {
      display: block;
      margin-bottom: 20px;
      color: rgba(53, 53, 53, 0.5);
      transition: all 200ms ease;
      cursor: pointer !important;

      &:hover {
        color: $purple;
      }
    }
  }
}

.social {
  display: flex;
  flex-direction: column;

  @include phone {
    margin-left: auto;
  }

  a {
    margin-bottom: 16px;
  }
}

// Basic

.basic-text {
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;
    padding-top: 10px;
  }

  p {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 15px;
    color: $dark;
    line-height: 1.73;
  }
}

.connect-card {
  position: relative;
  display: flex;
  background: #f7f7f7;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 16px 24px;

  &.inactive {
    figure {
      filter: grayscale(1);
    }
  }

  figure {
    width: 32px;
    height: 32px;
  }

  &.inactive {
    figure {
      filter: grayscale(1);
    }
  }

  .text {
    padding-left: 10px;

    p {
      font-size: 15px;
      font-weight: 600;

      &:last-child {
        padding-top: 4px;
      }
    }

    span {
      display: block;
      font-weight: 400;
      font-size: 14px;
      color: $text;

      i {
        margin-right: 4px;
      }
    }
  }

  h6 {
    margin-left: auto;
    padding-left: 10px;
    align-self: center;

    i {
      vertical-align: 0px;
    }
  }
}

.wallet-qr {
  figure {
    padding: 16px;
    border: 1px solid #cfcfcf;
    border-radius: 10px;
  }

  .d-flex {
    padding-top: 12px;
    justify-content: space-between;
    align-items: center;

    p {
      color: $text;
    }

    .btn {
      padding: 8px 36px;
      font-size: 14px;
    }
  }
}

// Pagination

.pagination {
  margin-bottom: 12px;

  li {
    margin-bottom: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;

    &:last-child {
      a {
        cursor: unset;
      }
    }

    a {
      border: none;
      font-weight: 400;
      font-size: 13px;
      color: $text;
      padding: 0;

      &:focus {
        outline: black;
        box-shadow: none;
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
        color: $black;
      }
    }

    &:not(:last-child) {
      &:after {
        content: "/";
        position: relative;
        display: inline-block;
        margin: 0 6px;
        color: $black;
        font-size: 12px;
      }
    }

    &:last-child {
      a {
        color: $black;
      }
    }
  }
}

// Tags

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px;

  > div {
    padding: 0 5px;
  }
}

// Toast

.Toastify {
  .Toastify__toast {
    background: #ffffff;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .Toastify__toast-body {
      font-size: 15px;
      font-weight: 400;
    }

    .Toastify__toast-icon {
      border-radius: 4px;
      margin-right: 20px;

      &:after {
        content: "";
        position: absolute;
        top: 4px;
        left: 2px;
        background-color: white;
        width: 16px;
        height: 12px;
        z-index: -1;
      }

      &:before {
        content: "";
        position: absolute;
        top: -6px;
        left: -6px;
        right: -6px;
        bottom: -6px;
        background: rgba(255, 136, 24, 0.08);
        border-radius: 6px;
      }
    }

    &.Toastify__toast--success {
      .Toastify__toast-icon {
        background-color: $orange;

        svg {
          fill: $orange;
        }
      }
    }

    &.Toastify__toast--error {
      .Toastify__toast-icon {
        background-color: $red;

        svg {
          fill: $red;
        }
      }
    }

    .Toastify__progress-bar--error {
      background-color: $red;
    }

    .Toastify__progress-bar--success {
      background-color: $orange;
    }
  }
}

// Tabs

.tabs {
  .nav-tabs {
    margin-bottom: 24px;
    justify-content: space-evenly;

    li {
      margin-bottom: 0;
      padding: 0 12px;

      a,
      button {
        border: none;
        margin: 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 1.15;
        color: $text;
        border-bottom: 2px solid transparent;
        padding: 12px 6px;

        @include tab-m {
          min-width: 200px;
        }

        &.active {
          border-bottom: 2px solid #ff8818;
          color: $black;
        }
      }
    }
  }
}

.asterisk {
  color: $orange;
}

.disabled {
  pointer-events: none;
}

.rc-slider-handle {
  opacity: 1;
  border: 1px solid #ffffff !important;
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.25) !important;
}

.tooltip-div {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 10;

  &:hover {
    z-index: 12;
  }

  .trig {
    &:hover + .text {
      z-index: 0;
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

  .text {
    position: absolute;

    top: calc(100% + 10px);
    min-width: 160px;
    right: 0;
    background-color: white;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    color: $black;
    padding: 8px 12px;
    border-radius: 6px;

    line-height: 1.4;
    font-size: 14px;

    opacity: 0;
    z-index: -1;
    visibility: hidden;
    display: none;

    @include phone-m {
      min-width: 340px;
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }

    &.xs {
      min-width: auto;
    }

    ul {
      list-style-type: disc;
      padding-left: 10px;
      margin-bottom: 0;

      li {
        color: #626262;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.32;
        margin-bottom: 0;

        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      border: 8px solid transparent;
      border-bottom-color: white;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  // &:hover{
  //   .text{
  //     opacity: 1;
  //   }
  // }
}

.notification-drop {
  // min-width: 440px;
  filter: drop-shadow(0px 5px 15px rgba(0, 0, 0, 0.2));
  border-radius: 4px;
  height: auto !important;
  max-height: 400px;

  @include no-scrollbar;

  @include phone {
    width: 90vw;
    max-width: 440px;
  }

  @include phone-m {
    min-width: 440px;
  }

  > a {
    margin-bottom: 0;

    &:not(:last-child) {
      box-shadow: 0px 1px 0px #e4e8ee;
    }

    &.unread {
      background-color: whitesmoke;
    }
  }

  .text {
    padding-left: 16px;
    max-width: calc(100% - 42px);
    white-space: break-spaces;
    p {
      word-wrap: break-word;
      &:not(.sm) {
        font-weight: 600;
        margin-bottom: 2px;
        white-space: unset;
      }

      &.sm {
        font-weight: 400;
        font-size: 13px;
        color: $text;
        margin-bottom: 0;
      }
    }
  }
}

.noti-head {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 10px;

  p {
    font-weight: 500;
  }
}

.g-card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 4px 48px rgba(197, 197, 197, 0.25);
  padding: 24px 20px;
  margin-bottom: 32px;

  .header {
    display: flex;
    flex-wrap: wrap;

    @include phone {
      padding-top: 10px;
      align-items: center;
    }

    figure {
      width: 64px;
      height: 64px;

      img {
        border-radius: 10px;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .ml-div {
      @include phone {
        position: absolute;
        right: 17px;
        top: 10px;
      }

      p {
        color: $gray;
      }
    }

    .text {
      padding-left: 12px;
      flex: 1 1;

      h6 {
        margin-bottom: 2px;
        @extend %trunc;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    padding: 12px 0 26px;
    border-top: 1px solid #c1c1c1;
    margin-top: 12px;

    @include phone-m {
      margin-top: 26px;
      align-items: center;
      flex-direction: row;
      padding: 19px 0 18px;
    }

    button {
      flex-shrink: 0;
      min-width: 83px;

      @include phone-m {
        margin-left: auto;
      }
    }

    p {
      padding-right: 24px;
      font-weight: 600;

      @include trunc(80%);

      @include phone {
        margin-bottom: 4px;
      }
    }
  }
}

.vote-card {
  position: relative;
  padding: 12px 20px;
  border: 1px solid #888888;
  border-radius: 10px;
  z-index: 2;
  transition: all 150ms ease;

  &.active {
    label {
      color: white;
      font-weight: 700;

      &:after {
        opacity: 1;
      }
    }
  }

  &.success {
    label {
      &:before {
        display: inline-block;
        background: url(../assets/images/icons/yes-icon.svg) no-repeat center;
      }
    }

    input {
      cursor: unset;
    }

    &:hover {
      border-color: #888888;
    }
  }

  &.failed {
    label {
      &:before {
        display: inline-block;
        background: url(../assets/images/icons/no-icon.svg) no-repeat center;
      }
    }
  }

  &:hover {
    border-color: $purple;
  }

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    z-index: 2;
    transition: all 150ms ease;
    display: flex;
    align-items: center;

    span {
      margin-left: auto;
    }

    @include phone-m {
      font-size: 16px;
    }

    &:before {
      content: "";
      position: relative;
      margin-right: 12px;
      width: 16px;
      height: 16px;
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(172.61deg, #7625ff 37.56%, #9924e9 94.26%);
      border-radius: 10px;
      opacity: 0;
      transition: all 150ms ease;
      z-index: -1;
    }
  }

  input {
    @include str-i;

    // &:checked+label{
    //     color: white;

    //     &:after{
    //         opacity: 1;
    //     }
    // }
  }
}

.basic-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding-top: 10px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  ul {
    list-style-type: disc;

    li {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.3;
      color: $black;
    }
  }

  a {
    color: $orange !important;
    text-decoration: underline !important;
    font-weight: 500;
  }
}

.no-items {
  text-align: center;
  margin-bottom: 24px;
}

.community {
  position: relative;
  background: url(../assets/images/banner-bg.png) no-repeat center;
  background-size: cover;
  padding: 100px 0;

  @include phone-m {
    min-height: 100vh;
    padding: 100px 0;
    display: flex;
    align-items: center;
  }

  .container {
    flex: 1 1;
    display: flex;
    align-items: center;

    .row {
      flex: 1 1;
      @include tab-m {
        margin: 0 -40px;
      }
    }
  }

  .text {
    text-align: center;
    color: white;

    @include phone {
      margin: 50px 0;
    }

    h5 {
      color: white;
    }

    p {
      color: white;
      font-size: 16px;
      margin-bottom: 4px;
    }
  }

  .btn-blue {
    display: inline-block;
    padding: 14px 48px;
    background-color: white;
    border-radius: 10px;
    margin-top: 24px;
    font-weight: 700;
    font-size: 16px;
    color: #5c72b0;

    i {
      margin-right: 6px;
    }

    @include phone-m {
      margin-top: 50px;
    }
  }

  .row {
    align-items: center;
  }

  .cm2 {
    max-width: 180px;
    margin: 0 auto;

    @include phone-m {
      max-width: 350px;
      margin-left: auto;
    }
  }

  .cm1 {
    @include phone {
      max-width: 180px;
      margin: 0 auto;
    }
  }
}
.d-voters {
  color: #150038;
  font-weight: 600;
}

.loader-full {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  background-color: rgba(black, 0.3);

  display: flex;
  align-items: center;
  justify-content: center;

  &.white{
    background-color: rgba(rgb(255, 255, 255), 1);
  }

  span {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 4px solid $orange;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spinner 1000ms ease-in-out infinite alternate forwards;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(1200deg);
  }
}

.loader-in {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1200;
  background-color: white;

  min-height: 300px;

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    border: 4px solid $orange;
    border-bottom-color: transparent;
    border-right-color: transparent;
    animation: spinner 1000ms ease-in-out infinite alternate forwards;
  }
}

.claim-alert {
  display: flex;
  align-items: center;

  padding: 16px 18px;
  border-radius: 10px;
  background-color: #ffebeb;
  margin-bottom: 24px;

  &.yellow {
    background-color: #fffbeb;
  }

  &.green {
    background-color: #f1ffeb;
  }
}

// @import './confetti';

.btn-label {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  border-radius: 10px;

  font-size: 16px;
  font-weight: 600;
  padding: 14px 10px;
  background-color: rgba($orange, 0.09);
  color: $orange;

  i {
    flex-shrink: 0;
    // filter: contrast(10);
  }

  h5 {
    margin-bottom: 2px;
  }
}

.terms-page {
  position: relative;
  min-height: 70vh;
  max-width: 1000px;
  margin: 0 auto;
}
.shareTwitterBtn {
  height: 50px;
  color: white !important;
  font-weight: 600 !important;
}

.viewMore_div {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
}
.ml-20 {
  margin-left: 20px;
}

.cookie-pop {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.546);
  z-index: 1080;
  display: flex;
  align-items: flex-end;

  .react-cookie-notice-container {
    position: relative;
    background-color: white;
    flex: 1 1 100%;
    padding: 20px 15px 24px;

    @include phone-m {
      padding: 24px 15px;
    }

    img {
      max-width: 90px;
      margin-bottom: 10px;

      @include phone-m {
        max-width: 120px;
      }
    }

    .react-cookie-notice-content {
      width: 100%;
      max-width: 1170px;
      padding: 0;

      display: flex;
      align-items: center;
      color: $black;

      @include phone {
        flex-direction: column;
        align-items: flex-start;
      }

      .text {
        max-width: 500px;
        @include phone-m {
          padding-left: 24px;
        }
      }

      h3,
      h4,
      h5 {
        color: $black;
        margin-bottom: 4px;
      }

      .react-cookie-notice-buttons {
        margin-top: 16px;
        @include phone-m {
          margin-left: auto;
          margin-top: 0;
        }

        .react-cookie-notice-button-primary {
          background-color: $orange;
          padding: 10px 24px;
          color: white;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 600;

          @include phone-m {
            font-size: 16px;
            padding: 12px 24px;
          }
        }
      }
    }
  }
}

.back-btn{
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: whitesmoke;
  border: 1px solid whitesmoke;
  margin-right: 6px;
  cursor: pointer;

  i{
    width: 20px;
    height: 20px;
    background-size: contain;
  }
}